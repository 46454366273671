import styled from 'styled-components';

const CenterContainer = styled.div`
  margin: auto;
  display: flex;
  outline: 0;
  position: relative;
  justify-content: center;
`;

export default CenterContainer;
