import React from 'react';
import { graphql, Link } from 'gatsby';
import { Typography, Container, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import SEO from '../components/seo';
import { BlogListQueryQuery, PageInfo } from '../../types/graphql-types';
import CenterContainer from '../components/Pagination/CenterContainer';
import GridCard from '../components/Blog/GridCard';

export interface BlogList {
  pageContext: PageInfo;
  data: BlogListQueryQuery;
}

const BlogList = ({ pageContext, data }: BlogList): JSX.Element => {
  const { allContentfulBlogPost, contentfulBlogList } = data;

  return (
    <>
      <SEO title="Blog" />

      <Container maxWidth="md">
        <Box mb={5} mt={5}>
          <Typography component="h1" variant="h3" align="left" color="textPrimary" gutterBottom>
            {contentfulBlogList?.title}
          </Typography>
          <Typography variant="h5" color="textSecondary" paragraph>
            {contentfulBlogList?.description?.description}
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {allContentfulBlogPost.edges.map(({ node }, index) => (
            <Box
              key={node.slug || index}
              mb={5}
              mt={index === 0 ? 5 : undefined}
              style={{ width: `100%` }}
            >
              <GridCard
                author={{
                  image: node?.author?.image?.gatsbyImageData || undefined,
                  name: node?.author?.name,
                  title: node?.author?.title,
                  slug: `/team/${node?.author?.slug}`,
                }}
                image={node?.heroImage?.gatsbyImageData}
                title={node.title}
                publishDate={node?.publishDate}
                description={node?.description?.description}
                slug={node.slug}
              />
            </Box>
          ))}
        </Grid>

        {!!pageContext.pageCount &&
          pageContext.currentPage !== null &&
          pageContext.currentPage !== undefined &&
          pageContext.pageCount > 1 && (
            <CenterContainer>
              <Pagination
                page={pageContext.currentPage}
                count={pageContext.pageCount}
                renderItem={(item): JSX.Element => (
                  <PaginationItem
                    component={Link}
                    to={`/blog${item.page === 1 ? '' : `/${item.page}`}`}
                    {...item}
                  />
                )}
              />
            </CenterContainer>
          )}
      </Container>
    </>
  );
};

export default BlogList;

export const blogListQuery = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!) {
    contentfulBlogList {
      title
      description {
        description
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            gatsbyImageData(width: 944, quality: 90)
          }
          description {
            description
          }
          slug
          author {
            title
            name
            slug
            image {
              gatsbyImageData(width: 40, height: 40, quality: 90)
            }
          }
        }
      }
    }
  }
`;
